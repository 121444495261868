.field.checkbox.label {
  align-items: baseline;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-small);
  font-weight: normal;
  position: relative;
  white-space: normal;

  input {
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    position: absolute;
    width: 1px;
  }

  & > .chekbox-tick {
    order: 1;
  }

  & > .label-text {
    color: var(--field-text-color);
    display: inline-block;
    margin: 0;
    margin-left: var(--gap-small);
    max-width: calc(100% - 3rem); /* checkbox width + margin [+ smth?] TODO: insert calc() */
    order: 2;
  }

  & > a {
    display: inline;

    &::after,
    &::before {
      content: ' ';
    }
  }

  & > .validation-message {
    flex-grow: 1;
    order: 3;
    width: 100%;
  }

  &:focus,
  &:focus-within {
    box-shadow: none;
    outline: 1px dotted var(--color-focus-outline);
  }

  & > input:disabled ~ .label-text {
    color: var(--field-disabled-color);
  }

  & > .description {
    order: 3;
  }
}
