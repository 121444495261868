/* TICK */
.checkbox-tick {
  align-items: center;
  background-color: var(--field-background-color);
  border: var(--checkbox-border-width) solid var(--field-border-color);
  border-radius: var(--field-border-radius);
  box-shadow: var(--focusable-box-shadow);
  color: var(--field-focus-border-color);
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--checkbox-tick-size);
  justify-content: center;
  position: relative;
  top: calc(0.5em - 0.5ch);
  transition: var(--field-transition);
  width: var(--checkbox-tick-size);

  & > svg {
    fill: currentColor;
    height: 100%;
    opacity: 0;
    position: relative;
    transform: scale(0);
    transition: all 0.2s ease;
    width: 100%;
  }

  input:focus + & {
    border-color: var(--field-focus-border-color);
    box-shadow: var(--focused-box-shadow);
  }

  input:checked + & {
    border-color: var(--field-focus-border-color);

    & > svg {
      opacity: 1;
      transform: scale(1);
    }
  }

  input.touched:invalid + & {
    border-color: var(--field-invalid-border-color);
  }

  input:disabled + & {
    background-color: var(--field-disabled-background-color);
    border-color: var(--field-disabled-border-color);
    color: var(--field-disabled-color);
  }
}
/* TICK CHECKED */
