.checkbox-option {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: normal;
  position: relative;

  &:not(:last-child) {
    margin-bottom: var(--gap-small);
  }

  &:focus,
  &:focus-within {
    box-shadow: none;
    outline: 1px dotted var(--color-focus-outline);
  }

  input {
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    position: absolute;
    width: 1px;

    &:disabled:not(:checked) ~ .option-label {
      color: var(--field-disabled-option-color);
    }
  }

  .option-label {
    color: var(--field-text-color);
    line-height: inherit;
    margin-left: var(--gap-small);
  }
}
