.field.file {
  --addon-width: 7rem;

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 0;
  overflow: visible;
  position: relative;

  & > .label-text {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: auto;
    order: 1;
  }

  & > .input {
    appearance: none;
    overflow: hidden;
    position: absolute;
    visibility: hidden;

    &:focus {
      border-color: var(--field-border-color);
      box-shadow: 0 0 transparent;
      outline: none;

      & ~ .browse-button {
        border-color: var(--field-focus-border-color);
        box-shadow: var(--focused-box-shadow);
        outline: none;
      }
    }
  }

  & > .pseudo-input {
    align-items: center;
    background-color: var(--field-background-color);
    border-color: var(--field-border-color);
    border-radius: var(--field-border-radius);
    border-style: solid;
    border-width: var(--field-border-width);
    box-shadow: var(--focusable-box-shadow);
    box-sizing: border-box;
    color: var(--field-text-color);
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    height: var(--field-height);
    line-height: var(--field-line-height);
    margin-right: calc(-1 * var(--addon-width) - 0.375rem);
    max-height: var(--field-height);
    max-width: 100%;
    min-height: var(--field-height);
    min-width: 0;
    order: 2;
    padding: var(--field-padding);
    padding-right: 0.25rem;
    position: relative;
    transition: all 0.15s ease-in-out;
    width: 100%;
  }

  .file-name {
    display: block;
    left: 0;
    line-height: var(--field-height);
    overflow: hidden;
    padding: var(--field-padding);
    position: absolute;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - var(--addon-width) - 1rem);
  }

  .input::-webkit-file-upload-button {
    display: none;
  }

  .browse-button {
    align-items: center;
    background-color: var(--button-secondary-background-color);
    border: 1px solid transparent;
    border-radius: var(--field-border-radius);
    box-shadow: var(--focusable-box-shadow);
    display: flex;
    height: 1.875rem;
    justify-content: center;
    left: -1rem;
    margin: calc((var(--field-height) - 1.875rem) / 2) 0;
    order: 3;
    width: var(--addon-width);
    z-index: 1;

    &:focus {
      border-color: var(--field-focus-border-color);
      box-shadow: var(--focused-box-shadow);
      outline: none;
    }
  }
}

.browse-button::-moz-focus-inner {
  outline: none;
}
