.label.field.date:not(.date-firefox) {
  & > input,
  & > .date-input-wrapper > input {
    &:not(.cleave) {
      background-color: var(--field-background-color);
      position: relative;

      &::before {
        background-color: var(--field-addon-color);
        content: '';
        height: 1.25rem;
        mask-image: url('../../../img/calendar.svg');
        mask-size: 1.25rem 1.25rem;
        position: absolute;
        right: 0.35rem;
        width: 1.25rem;
      }
    }
    /* max-width: 11rem; */
    &::-webkit-inner-spin-button {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    & ~ .label-text {
      flex-basis: 100%;
    }
  }

  & > .date-input-wrapper > input[readonly],
  & > input[readonly] {
    background-color: var(--field-disabled-background-color);
    border-color: var(--field-disabled-border-color);
    color: var(--field-disabled-color);

    &::before {
      background-color: var(--field-disabled-color);
    }
  }
}

.label.field.date.date-firefox {
  & > input,
  & > .date-input-wrapper > input {
    &:not(.cleave) {
      background-color: var(--field-background-color);
      position: relative;
    }
    /* max-width: 11rem; */
    &::-webkit-inner-spin-button {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    & ~ .label-text {
      flex-basis: 100%;
    }
  }

  & > .date-input-wrapper > input[readonly],
  & > input[readonly] {
    background-color: var(--field-disabled-background-color);
    border-color: var(--field-disabled-border-color);
    color: var(--field-disabled-color);

    &::before {
      background-color: var(--field-disabled-color);
    }
  }
}
