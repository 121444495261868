::-webkit-input-placeholder {
  color: var(--field-placeholder-color);
}

::-moz-placeholder {
  color: var(--field-placeholder-color);
}

:-ms-input-placeholder {
  color: var(--field-placeholder-color);
}

:-moz-placeholder {
  color: var(--field-placeholder-color);
}

.field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;

  &.required:not(.disabled):not(.read-only) {
    .label-text::after {
      content: '*';
      display: inline;
      font-weight: var(--bold);
      margin-left: 0.25rem;
    }
  }

  & > .label-text {
    order: 1;
    overflow: hidden;
    padding-right: 0.5rem;
    text-overflow: ellipsis;
    transition: 0.2s ease transform;
  }

  & > .description {
    order: 2;
    width: 100%;
  }

  & .description ~ .label-text {
    margin-right: 2rem;
  }

  & > .input {
    flex-grow: 1;
    flex-shrink: 0;
    order: 3;
    width: 100%;
  }

  & > .validation-message {
    order: 4;
  }

  & > input {
    height: var(--field-height);
    max-height: var(--field-height);
    min-height: var(--field-height);
  }

  & > textarea {
    resize: vertical;
  }

  &:hover {
    > input,
    > textarea {
      border-color: var(--field-hover-border-color);
      color: var(--field-hover-text-color);
    }
  }

  & > input,
  & > textarea {
    background-color: var(--field-background-color);
    border-color: var(--field-border-color);
    border-radius: var(--field-border-radius);
    border-style: solid;
    border-width: var(--field-border-width);
    box-shadow: var(--focusable-box-shadow);
    box-sizing: border-box;
    color: var(--field-text-color);
    line-height: var(--field-line-height);
    padding: var(--field-padding);
    transition: var(--field-transition);

    &:active,
    &:focus:not(:read-only) {
      border-color: var(--field-focus-border-color);
      box-shadow: var(--focused-box-shadow);
      color: var(--field-focus-text-color);
      outline: none;
    }

    &.touched.invalid,
    &.touched:invalid {
      border-color: var(--field-invalid-border-color);
    }

    &[required] ~ .label-text::after {
      /* content: '*'; */
    }

    &[readonly] {
      background-color: var(--field-read-only-background-color);
      border-color: var(--field-read-only-border-color);
      color: var(--field-read-only-color);
      cursor: default;
      outline: none;
    }

    &:disabled {
      background-color: var(--field-disabled-background-color);
      border-color: var(--field-disabled-border-color);
      color: var(--field-disabled-color);
      cursor: default;

      & ~ .label-text {
        color: var(--field-label-disabled-color);
      }
    }
  }

  & > .tooltip {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: var(--field-height);
    left: 1.5rem;
    margin-left: -1rem;
    order: 3;
    position: relative;

    @media (--phone) {
      left: 0;
      margin: 0;
      position: static;
      width: 100%;

      .dropdown-button {
        display: none;
      }

      & > .dropdown-content {
        display: block;
        position: static;
        text-align: left;

        & > .tooltip-text {
          background-color: transparent;
          color: var(--field-label-color);
          padding: 0;
          position: relative;
          z-index: 0;

          & > svg {
            display: none;
          }
        }
      }
    }
  }
}
