.field.currency {
  --addon-width: 2rem;
  --border-radius: 0.25rem;

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 0;
  overflow: visible;
  position: relative;

  & > .label-text {
    flex-shrink: 0;
    width: 100%;
  }

  & > .description {
    width: 100%;
  }

  & > .currency-label {
    align-items: center;
    border-right: 1px solid var(--field-border-color);
    color: var(--field-addon-color);
    display: flex;
    fill: currentColor;
    flex-grow: 0;
    flex-shrink: 0;
    height: calc(var(--field-height) - 2px);
    justify-content: center;
    line-height: var(--field-line-height);
    margin: 1px 0 0;
    max-width: var(--addon-width);
    order: 3;
    padding-top: 1px;
    pointer-events: none;
    position: relative;
    transition: border-color 0.15s ease-in-out;
    width: calc(var(--addon-width) - 1px);
  }

  & > .input {
    flex-grow: 1;
    margin-left: calc(-1 * var(--addon-width));
    max-width: 100%;
    min-width: 0;
    order: 4;
    padding-left: calc(var(--addon-width) + var(--field-padding-horizontal) + 1px);
    width: 100%;
  }

  &:hover > .currency-label {
    /* border-color: var(--field-hover-border-color); */
  }

  .input:focus ~ .currency-label {
    border-color: var(--field-focus-border-color);
  }
}
