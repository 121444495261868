.description {
  & > .icon-button {
    color: var(--color-brand);
    position: absolute;
    right: 0;
    top: calc(var(--field-label-font-size) / -2);
    z-index: 3;
  }

  .info {
    color: var(--field-label-color);
    font-size: var(--font-size-tiny);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;

    &::after {
      content: '';
      display: block;
      height: 0.5rem;
    }

    &.open {
      max-height: 3500px;
      transition: max-height 0.125s ease-in-out;
    }
  }
}
